<template>
  <div>
    <div class="form-group">
        <p>We've received your registration request. Your application is pending review by Less Grey Rep B.V. and you will be informed about the further steps by the provided email <span class="text-primary text-bold">{{ userEmail }}</span></p>
    </div>
    <div class="form-group">
        <p>If you have any further questions you can always contact us at <a class="text-primary" href="mailto:info@lessgrey.eu">info@lessgrey.eu</a></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Step10",
  computed: {
    ...mapGetters("form", [
      "userEmail",
    ])
  },
};
</script>

